import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { useBlogPageSeo } from "../hooks/useBlogPageSeo/useBlogPageSeo";
import { useFeaturedBlogPost } from "../hooks/useFeaturedBlogPost/useFeaturedBlogPost";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import BlogPageHeader from "../components/BlogPageHeader/BlogPageHeader";
import BlogPageListing from "../components/BlogPageListing/BlogPageListing";
import BlogPagination from "../components/BlogPagination/BlogPagination";
import Footer from "../components/Footer/Footer";

const BlogArchive = ({
  data,
  pageContext: { nextPagePath, previousPagePath, totalPages, currentPage },
}) => {
  const featuredPost = useFeaturedBlogPost();
  const posts = data.allWpPost.nodes;
  const blogPageSeo = useBlogPageSeo();

  if (!posts.length) {
    return (
      <Layout>
        <Header />
        <p>
          No blog posts found. Add posts to your WordPress site and they`ll
          appear here!
        </p>
        {/* <Footer /> */}
      </Layout>
    );
  }

  return (
    <>
      <Seo post={blogPageSeo} />
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
      />
      <Layout>
        <Header />
        <BlogPageHeader data={featuredPost.length ? featuredPost[0] : null} />
        <BlogPageListing data={posts} />
        <BlogPagination
          nextPagePath={nextPagePath}
          previousPagePath={previousPagePath}
          currentPage={currentPage}
          totalPages={totalPages}
        />
        <Footer />
      </Layout>
    </>
  );
};

export default BlogArchive;

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        blogSinglePost {
          author {
            firstName
            lastName
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  height: 500
                  width: 600
                )
              }
            }
            altText
          }
        }
        categories {
          nodes {
            uri
            name
          }
        }
        isSticky
        author {
          node {
            uri
            name
            firstName
            lastName
          }
        }
      }
    }
  }
`;
