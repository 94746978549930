import { useStaticQuery, graphql } from "gatsby";

export const useFeaturedBlogPost = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        sort: { fields: [date], order: DESC }
        limit: 10
        skip: 0
        filter: { isSticky: { eq: true } }
      ) {
        nodes {
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          blogSinglePost {
            author {
              firstName
              lastName
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, height: 500, width: 600)
                }
              }
              altText
            }
          }
          categories {
            nodes {
              uri
              name
            }
          }
          isSticky
          author {
            node {
              uri
              name
              firstName
              lastName
            }
          }
          excerpt
        }
      }
    }
  `);
  return data.allWpPost.nodes;
};
