import React from "react";
import uniqueId from "lodash/uniqueId";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Spacer from "../Spacer/Spacer";
import IsrLabel from "../IsrLabel/IsrLabel";
import IsrButton from "../IsrButton/IsrButton";
import "./BlogPageHeader.scss";

const BlogPageHeader = ({ data }) => {
  if (!data) {
    return <></>;
  }
  const {
    title,
    uri,
    featuredImage,
    date,
    categories,
    author,
    excerpt,
    blogSinglePost,
  } = data;

  let authorToShow = "";
  if (
    blogSinglePost &&
    blogSinglePost.author &&
    (blogSinglePost.author.firstName || blogSinglePost.author.lastName)
  )
    authorToShow = `${
      blogSinglePost.author.firstName ? blogSinglePost.author.firstName : ""
    } ${blogSinglePost.author.lastName ? blogSinglePost.author.lastName : ""}`;
  else
    authorToShow = `${author.node.firstName ? author.node.firstName : ""} ${
      author.node.lastName ? author.node.lastName : ""
    }`;

  return (
    <section className="blog-page-header">
      <div className="container">
        <Spacer size={100} mobileSize={50} />
        <div className="row">
          <div className="col-12 col-lg-7">
            <h1 className="h2">ISR Blog</h1>
            <hr size="3" className="divider" />
            <article className="featured-article">
              {title && <h2>{title}</h2>}
              {date && <span className="date">{date}</span>}
              {author && <span className="author">{authorToShow}</span>}
              {categories && (
                <div className="categories">
                  {categories.nodes.map((category, index) => (
                    <IsrLabel
                      variant="secondary"
                      size="sm"
                      color="grey"
                      key={uniqueId("bp_isr_")}
                    >
                      <Link to={category.uri} title={category.name}>
                        {category.name}
                      </Link>
                    </IsrLabel>
                  ))}
                </div>
              )}
              {excerpt && (
                <div
                  className="post-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: excerpt,
                  }}
                ></div>
              )}
              <IsrButton type="button" variant="primary" size="md">
                {uri && (
                  <Link to={uri}>
                    <strong>Read More</strong>
                  </Link>
                )}
              </IsrButton>
            </article>
          </div>
          <div className="col-12 col-lg-5">
            <GatsbyImage
              image={
                featuredImage.node.localFile.childImageSharp.gatsbyImageData
              }
              alt={author.node.name}
              className="featured-image"
            />
          </div>
        </div>
        <Spacer size={100} mobileSize={50} />
      </div>
    </section>
  );
};

export default BlogPageHeader;
