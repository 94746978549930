import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import "./BlogPagination.scss";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-textPrimary.Mui-selected": {
      backgroundColor: "#70e8cb",
      color: "#000000",
    },
    "& .MuiPaginationItem-textPrimary.Mui-selected:hover, & .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible":
      {
        backgroundColor: "#70e8cb !important",
      },
  },
}));

const BlogPagination = ({ currentPage, totalPages, pagePath }) => {
  const classes = useStyles();
  return (
    <section className="blog-pagination">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pagination">
              {totalPages > 1 && (
                <Pagination
                  classes={{ ul: classes.ul }}
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      to={`${pagePath ? pagePath : "/blog/"}${
                        item.page === 1 ||
                        item.page === 0 ||
                        item.page > totalPages
                          ? ""
                          : `page/${item.page}/`
                      }`}
                      {...item}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogPagination;
