import React from "react";
import uniqueId from "lodash/uniqueId";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import IsrLabel from "../IsrLabel/IsrLabel";
import IsrButton from "../IsrButton/IsrButton";
import "./BlogPageItem.scss";

const BlogPageItem = ({
  postData: {
    categories,
    date,
    featuredImage,
    title,
    uri,
    author,
    blogSinglePost,
  },
}) => {
  const breakPoints = useBreakpoint();
  const getTitle = () => {
    let theTitle = `${title.slice(0, 95)}...`;
    breakPoints.xs ? (theTitle = title) : null;
    breakPoints.sm ? (theTitle = title) : null;
    breakPoints.md ? (theTitle = title) : null;
    breakPoints.l ? (theTitle = `${title.slice(0, 70)}...`) : null;
    return theTitle;
  };
  let authorToShow = "";
  if (
    blogSinglePost &&
    blogSinglePost.author &&
    (blogSinglePost.author.firstName || blogSinglePost.author.lastName)
  )
    authorToShow = `${
      blogSinglePost.author.firstName ? blogSinglePost.author.firstName : ""
    } ${blogSinglePost.author.lastName ? blogSinglePost.author.lastName : ""}`;
  else
    authorToShow = `${author.node.firstName ? author.node.firstName : ""} ${
      author.node.lastName ? author.node.lastName : ""
    }`;
  return (
    <div className="col-12 col-lg-4">
      <div className="blog-item">
        <h4>
          <Link to={uri} title={title}>
            {title.length > 128 ? getTitle() : title}
          </Link>
        </h4>
        <span className="date">{date}</span>
        <span className="author">{authorToShow}</span>
        <Link to={uri} title={title}>
          {featuredImage ? (
            <GatsbyImage
              image={
                featuredImage.node.localFile.childImageSharp.gatsbyImageData
              }
              alt={`${title}`}
              className="featured-image article-image"
            />
          ) : (
            <StaticImage
              className="placeholder-image article-image"
              src="../../assets/images/placeholder.png"
              alt="NYAS - Placeholder blog image"
            />
          )}
        </Link>
        <div className="article-footer">
          {categories && (
            <div className="categories">
              {categories.nodes.map((category) => (
                <Link
                  to={category.uri}
                  title={category.name}
                  key={uniqueId("bp_cat_")}
                >
                  <IsrLabel variant="secondary" size="sm" color="grey">
                    {category.name}
                  </IsrLabel>
                </Link>
              ))}
            </div>
          )}
          <Link to={uri} className="link-arrow">
            <IsrButton type="button" variant="arrow" size="sm" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPageItem;
