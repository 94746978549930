import { useStaticQuery, graphql } from "gatsby";

export const useAllAuthors = () => {
  const data = useStaticQuery(graphql`
    query allWpUser {
      allWpUser {
        nodes {
          uri
          name
          id
          posts {
            nodes {
              id
            }
          }
        }
      }
    }
  `);
  return data.allWpUser.nodes;
};
