import React from "react";
import { Link } from "gatsby";
import propTypes from "prop-types";
import uniqueId from "lodash/uniqueId";
import "react-tabs/style/react-tabs.css";
import "../IsrTabs/IsrTabs.scss";
import "./IsrAuthorTabs.scss";

const IsrAuthorTabs = ({ items, activeId }) => {
  return (
    <div className="tabs-container -center -authors">
      <ul className="tab-list">
        <li className={`tab ${activeId ? "" : "active"}`}>
          <Link to="/blog/">All Authors</Link>
        </li>
        {items &&
          items.map((item) => (
            <li
              className={`tab ${item.id === activeId ? "active" : ""}`}
              key={uniqueId("isr_")}
            >
              <Link to={item.uri}>{item.name}</Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

IsrAuthorTabs.propTypes = {
  items: propTypes.array.isRequired,
};

export default IsrAuthorTabs;
