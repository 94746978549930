import { useStaticQuery, graphql } from "gatsby";
export const useBlogPageSeo = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(link: { eq: "/blog/" }) {
        seo {
          canonical
          cornerstone
          focuskw
          fullHead
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
          title
          twitterTitle
          schema {
            raw
            pageType
            articleType
          }
          breadcrumbs {
            url
            text
          }
          twitterDescription
          twitterImage {
            mediaItemUrl
            sourceUrl
          }
          opengraphImage {
            mediaItemUrl
            sourceUrl
          }
        }
      }
    }
  `);
  return data?.wpPage;
};
