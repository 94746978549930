import React from "react";
import uniqueId from "lodash/uniqueId";
import IsrAuthorTabs from "../IsrAuthorTabs/IsrAuthorTabs";
import BlogPageItem from "../BlogPageItem/BlogPageItem";
import { useAllAuthors } from "../../hooks/useAllAuthors/useAllAuthors";
import "./BlogPageListing.scss";

const BlogPageListing = ({ data, authorId }) => {
  // Get all authors and filter if they have posts
  const allAuthors = useAllAuthors().filter(
    (author) => author?.posts?.nodes?.length > 0
  );

  return (
    <section className="blog-posts-listing">
      <div className="container">
        <hr size="1" className="divider" />
        <IsrAuthorTabs items={allAuthors} activeId={authorId} />
        <div className="row">
          {data &&
            data.map((post) => (
              <BlogPageItem postData={post} key={uniqueId("bl_isr_")} />
            ))}
        </div>
      </div>
    </section>
  );
};
export default BlogPageListing;
